import axios from 'axios';
import {
  CHECKOUT_ENDPOINT,
  DELETE_DISCOUNT_CODE,
  GET_ADDRESS,
  GET_ORDER_HISTORY,
  PHONE_CONTACT,
  POST_ADDRESS,
  POST_CHECK_VALID_EMAIL,
  POST_SEND_VERIFY_EMAIL_OTP,
  POST_SEND_VERIFY_SMS_CODE,
  POST_SEND_VERIFY_SMS_CODE_GUEST,
  PUT_PLACE_ORDER_CHECKOUT,
  REGISTER,
  UPDATE_PASSWORD_GUEST,
  FETCH_CART_ENDPOINT,
} from '../../config';
import { beginCheckout } from '../../utils/helpers/productTracker';
import {
  fetchClient,
  formatPhoneNumber,
  toastrError,
  toastrSuccess,
  validateEmail,
  validatePhone,
} from '../Helpers';
import store from '../store';
import { setCheckoutV2StateAction } from './actions';

export async function fetchCart() {
  store.dispatch(setCheckoutV2StateAction({ loading: true }));
  const url = FETCH_CART_ENDPOINT;
  try {
    const options = {
      url: url,
      method: 'GET',
      body: null,
    };
    const res: any = await fetchClient(options);
    store.dispatch(
      setCheckoutV2StateAction({
        cart: { ...store?.getState()?.checkoutV2Reducer?.cart, ...res?.data },
        loading: false,
      }),
    );

    return res?.data;
  } catch (error) {
    store.dispatch(setCheckoutV2StateAction({ loading: false }));
    return null;
  }
}

export async function updateUserAddress(selectedAddress: any, type: any, data: any) {
  store.dispatch(setCheckoutV2StateAction({ loading: true }));
  try {
    const options = {
      url: `${POST_ADDRESS}${selectedAddress ? selectedAddress?.id + '/' : ''}?type=${type}`,
      method: selectedAddress ? 'PUT' : 'POST',
      body: {
        ...data,
        user_id: localStorage.getItem('sundora_guest_id'),
      },
    };
    const res: any = await fetchClient(options);
    if (type == 'shipping') {
      store.dispatch(setCheckoutV2StateAction({ address: res?.data }));
    } else {
      store.dispatch(
        setCheckoutV2StateAction({
          billingAddress: res?.data,
          selectedBillingAddress: selectedAddress || res?.data?.[0],
        }),
      );
    }

    store.dispatch(setCheckoutV2StateAction({ loading: false }));
    return res?.data;
  } catch (error) {
    store.dispatch(setCheckoutV2StateAction({ loading: false }));
    return null;
  }
}

export async function updateCartAddress(address: any, deliveryType: any = 'standard') {
  store.dispatch(setCheckoutV2StateAction({ loading: true }));
  try {
    const guestId = localStorage.getItem('sundora_guest_id');
    const options: any = {
      url: `${CHECKOUT_ENDPOINT}shipping_address/`,
      method: 'POST',
      body: {
        user_address_id: address
          ? address?.id
          : store?.getState()?.checkoutV2Reducer?.selectedAddress?.id,
        company: '',
        delivery_type: deliveryType,
      },
    };
    if (guestId) {
      options.body.user_id = parseInt(guestId);
    }
    const response = await fetchClient(options);
    if (response?.isError) {
      if (response?.message?.shipping) {
        toastrError(response?.message?.shipping);
        store.dispatch(
          setCheckoutV2StateAction({
            deliveryType: 'standard',
            loading: false,
          }),
        );
        return null;
      }
    }
    store.dispatch(
      setCheckoutV2StateAction({
        cart: { ...store?.getState()?.checkoutV2Reducer?.cart, ...response?.data },
        deliveryType: deliveryType,
        selectedAddress: address,
        loading: false,
      }),
    );
    return { response: response.data };
  } catch (err: any) {
    store.dispatch(
      setCheckoutV2StateAction({
        loading: false,
      }),
    );
    return null;
  }
}

export async function fetchUserAddress() {
  const guestId = localStorage.getItem('sundora_guest_id');
  const sundoraToken = localStorage.getItem('sundoraToken');
  if (!guestId && !sundoraToken) {
    return;
  }

  store.dispatch(setCheckoutV2StateAction({ loadingFetchAddress: true }));
  try {
    const options = {
      url: `${GET_ADDRESS}?type=shipping&user_id=${guestId}`,
      method: 'GET',
      body: null,
    };

    const billingOptions = {
      url: `${GET_ADDRESS}?type=billing&user_id=${guestId}`,
      method: 'GET',
      body: null,
    };
    const res: any = await Promise.all([fetchClient(options), fetchClient(billingOptions)]);
    const defaultAddress = res?.[0]?.data?.find((x: any) => x?.default == true);
    store.dispatch(
      setCheckoutV2StateAction({
        address: res?.[0]?.data,
        billingAddress: res?.[1]?.data?.[0] ? [res?.[1]?.data?.[0]] : [],
        selectedAddress: defaultAddress,
        selectedBillingAddress: res?.[1]?.data?.[0],
      }),
    );
    store.dispatch(setCheckoutV2StateAction({ loadingFetchAddress: false }));
    return res?.data;
  } catch (error) {
    store.dispatch(setCheckoutV2StateAction({ loadingFetchAddress: false }));
    return null;
  }
}

function getCookie(cname: any) {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    const c = ca[i].trim();
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export async function placeOrder() {
  const checkoutV2State: any = store?.getState()?.checkoutV2Reducer;
  //const guest = !!localStorage.getItem('sundora_guest_id');
  const aff = getCookie('aff');
  let id_user = '';
  let id_token = '';
  let aff_uid = '';
  const get_aff_result = getCookie('affiliatly_v3');

  if (get_aff_result && get_aff_result != '') {
    const decodedValue = decodeURIComponent(get_aff_result);

    // Tách giá trị thành các cặp key-value
    const valuePairs = decodedValue.split('&');

    // Tạo đối tượng từ các cặp key-value
    const affiliatlyData: any = {
      id_user: '',
      id_token: '',
      aff_uid: '',
    };
    valuePairs.forEach((pair) => {
      const [key, value] = pair.split('=');
      if (key == 'id_user') {
        affiliatlyData.id_user = value;
      } else if (key == 'id_token') {
        affiliatlyData.id_token = value;
      } else if (key == 'aff_uid') {
        affiliatlyData.aff_uid = value;
      }
    });

    // Truy cập các giá trị
    id_user = affiliatlyData.id_user;
    id_token = affiliatlyData.id_token;
    aff_uid = affiliatlyData.aff_uid;
  }

  const body: any = {
    address_id: checkoutV2State?.selectedAddress?.id,
    address_billing_id: checkoutV2State?.selectedBillingAddress?.id,
    referral_code: checkoutV2State?.referralCode
      ? checkoutV2State?.paymentMethod == 'cash_on_pickup' && checkoutV2State?.referralCode
      : null,
    cash_on_pickup: checkoutV2State?.paymentMethod == 'cash_on_pickup' ? true : false,
    aff: aff,
    id_token: id_token,
    id_user: id_user,
    aff_uid: aff_uid,
  };
  // CASH_ON_DELIVERY FOR SAME_DAY_DELIVERY
  // if (body.cash_on_pickup && checkoutV2State?.cart?.shipping_line?.title == 'Same Day') {
  //   toastrError('Cash on Delivery option is not available for express delivery');
  //   return;
  // }

  if (!localStorage.getItem('sundoraToken')) {
    toastrError('Please login to proceed');
    return;
  }
  if (body?.address_id == null && body?.store_location_id == null) {
    toastrError('Please choose delivery address');
    return;
  }

  localStorage.setItem('last_order', JSON.stringify(checkoutV2State));

  store.dispatch(setCheckoutV2StateAction({ loading: true }));

  const options = {
    url: `${PUT_PLACE_ORDER_CHECKOUT}pay/`,
    method: 'POST',
    body: body,
  };

  fetchClient(options)
    .then((res) => {
      if (res?.success) {
        if (res?.data?.type == 'cash_on_pickup') {
          // window.location.href = `order-success`;
          window.location.href = `order-verify/success/${res?.data?.order}/`;
          return;
        }
        beginCheckout(store?.getState()?.productReducer);
        if (checkoutV2State?.paymentMethod != 'bkash') {
          window.location.href = res?.data?.redirectGatewayURL;
        }
      }
      if (res?.isError) {
        if (res?.message?.cart) {
          toastrError(Object.values(res?.message?.cart));
        } else {
          toastrError(Object.values(res?.message)?.[0]);
          toastrError(res?.message?.[Object.keys(res?.message)?.[0]]);
        }
      }
      store.dispatch(setCheckoutV2StateAction({ loading: false }));
    })
    .catch(() => {
      store.dispatch(setCheckoutV2StateAction({ loading: false }));
    });
}

export async function updateCart(
  items: any = null,
  paymentMethod: any = null,
  flag: any = null,
  line_item: any = null,
) {
  const checkoutV2State: any = store?.getState()?.checkoutV2Reducer;
  const currentLineItems: any = checkoutV2State?.cart?.line_items;
  const discountCode: any = checkoutV2State?.discountCode;
  const referralCode: any = checkoutV2State?.referralCode;
  const removeDiscountReferral: any = checkoutV2State?.removeDiscountReferral;
  if (paymentMethod) {
    store?.dispatch(setCheckoutV2StateAction({ paymentMethod }));
  }
  if (!items) {
    items = currentLineItems;
  } else {
    const outStockLineItemsName: any = [];
    const outStockVariantId: any = [];
    currentLineItems?.forEach((item: any) => {
      if (item?.inventory_quantity < 1) {
        outStockLineItemsName.push(item?.name);
        outStockVariantId.push(item?.variant_id);
      }
    });

    if (outStockLineItemsName?.length > 0) {
      toastrError(
        `${outStockLineItemsName?.toString()} had to be removed from you cart because it is no longer available in stock`,
      );
    }

    items = items?.filter((x: any) => !outStockVariantId?.includes(x?.variant_id));
  }

  let aff = '';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf('aff=') == 0) {
      aff = c.substring(4, c.length);
    }
  }

  const body = {
    draft_order: {
      line_items: items,
    },
    delivery_type: checkoutV2State?.deliveryType,
    payment_method: paymentMethod,
    discount_code: discountCode,
    referral_code: referralCode,
    user_id: checkoutV2State?.guest?.id,
    flag: referralCode && removeDiscountReferral ? removeDiscountReferral : flag,
    available_rewards: checkoutV2State?.available_rewards,
    aff: aff,
    line_item: line_item,
  };

  const options: any = {
    url: `${CHECKOUT_ENDPOINT}`,
    method: 'PUT',
    body: body,
  };

  store.dispatch(setCheckoutV2StateAction({ loading: true }));

  fetchClient(options)
    .then((res: any) => {
      if (!res?.success) {
        if (res?.message?.cart) {
          toastrError(Object.values(res?.message?.cart));
        } else {
          toastrError(Object.values(res?.message)[0]);
        }
        store.dispatch(
          setCheckoutV2StateAction({
            cart: { ...checkoutV2State?.cart, updateCartItemsAt: new Date().getTime() },
            loading: false,
          }),
        );
      } else {
        store.dispatch(
          setCheckoutV2StateAction({
            cart: { ...store?.getState()?.checkoutV2Reducer?.cart, ...res?.data },
            loading: false,
          }),
        );
      }
    })
    .catch((err: any) => {
      console.log(err);
      toastrError(Object.values(err?.response?.data?.errors)[0]);

      store.dispatch(
        setCheckoutV2StateAction({
          cart: { ...checkoutV2State?.cart, updateCartItemsAt: new Date().getTime() },
          loading: false,
        }),
      );
    });
}

export async function updatePayment(payment: any) {
  store.dispatch(setCheckoutV2StateAction({ loading: true }));
  const options = {
    url: `${CHECKOUT_ENDPOINT}payment_method/`,
    method: 'POST',
    body: {
      paymentMethod: payment,
    },
  };
  fetchClient(options).then((res) => {
    if (res?.success) {
      store.dispatch(
        setCheckoutV2StateAction({
          cart: { ...store?.getState()?.checkoutV2Reducer?.cart, ...res?.data },
          loading: false,
        }),
      );
    } else if (res?.isError) {
      toastrError(res?.message?.discount);
      store.dispatch(setCheckoutV2StateAction({ loading: false }));
      return;
    }
  });
}

export async function applyDiscountCode(discountCode: any) {
  store.dispatch(setCheckoutV2StateAction({ loading: true, discountCode }));

  const options = {
    url: `${CHECKOUT_ENDPOINT}discount_code/`,
    method: 'POST',
    body: {
      discount: {
        code: discountCode,
      },
    },
  };

  fetchClient(options).then((res) => {
    if (res?.success) {
      toastrSuccess(`Coupon ${discountCode} was successfully applied to cart`);
      store.dispatch(
        setCheckoutV2StateAction({
          cart: { ...store?.getState()?.checkoutV2Reducer?.cart, ...res?.data },
          loading: false,
        }),
      );
    } else if (res?.isError) {
      toastrError(res?.message?.discount);
      store.dispatch(setCheckoutV2StateAction({ loading: false }));
      return;
    }
  });
}

export const deleteDiscountCode = async () => {
  const options = {
    url: DELETE_DISCOUNT_CODE,
    method: 'POST',
    body: null,
    catchHandle: () => {
      console.log();
    },
  };
  store.dispatch(setCheckoutV2StateAction({ loading: true }));
  return await fetchClient(options).then((res: any) => {
    const resData: any = res?.data;
    if (resData?.success) {
      toastrSuccess('Coupon was removed');
      store.dispatch(
        setCheckoutV2StateAction({
          cart: { ...store?.getState()?.checkoutV2Reducer?.cart, ...resData?.draft_order },
          loading: false,
        }),
      );
      return true;
    }
    store.dispatch(setCheckoutV2StateAction({ loading: false }));
    return false;
  });
};

export const checkValidPhoneOrEmail = async (value: any, type: any = 'phone') => {
  if (type == 'phone') {
    if (!value || value == '') {
      return 'Please enter your phone number';
    }
    value = formatPhoneNumber(value);
    const validPhone = validatePhone(value);
    if (validPhone == false) {
      return `Error phone, Need help? Please call: ${PHONE_CONTACT}`;
    }
  } else if (type == 'emailOrPhone') {
    if (!value || value == '') {
      return 'Please enter your email or phone number';
    }
    if (value?.includes('@')) {
      const validEmail = validateEmail(value);
      if (validEmail == false) {
        return `Error email or phone, Need help? Please call: ${PHONE_CONTACT}`;
      }
    } else {
      value = formatPhoneNumber(value);
      const validPhone = validatePhone(value);
      if (validPhone == false) {
        return `Error email or phone, Need help? Please call: ${PHONE_CONTACT}`;
      }
    }
  } else {
    if (!value || value == '') {
      return 'Please enter your email';
    }
    const validEmail = validateEmail(value);
    if (validEmail == false) {
      return `Error email, Need help? Please call: ${PHONE_CONTACT}`;
    }
  }

  const options = {
    url: POST_CHECK_VALID_EMAIL,
    method: 'POST',
    body: {
      email: value,
    },
  };
  try {
    const response = await fetchClient(options);
    if (response.success) {
      return false;
    }
    return true;
  } catch (error) {
    return true;
  }
};

export const registerGuest = async (data: any) => {
  const options = {
    url: REGISTER,
    method: 'POST',
    body: data,
  };

  store.dispatch(setCheckoutV2StateAction({ loading: true }));
  const res: any = await fetchClient(options)
    .then((res: any) => {
      if (res?.success) {
        document.cookie = `sundora_guest_id=${res?.data?.id}`;
        localStorage.setItem('sundora_guest_id', res?.data?.id);
        store.dispatch(setCheckoutV2StateAction({ guest: res?.data, loading: false }));
        return true;
      }
      store.dispatch(setCheckoutV2StateAction({ loading: false }));
      return false;
    })
    .catch(() => {
      store.dispatch(setCheckoutV2StateAction({ loading: false }));
      return false;
    });

  return res;
};

export const setOTPExpiry = () => {
  const siteSettingsReducer: any = store?.getState()?.siteSettingsReducer;
  const now = Math.floor(Date.now() / 1000);
  const resendLimit = siteSettingsReducer.SMS_TIMER * 60;
  const codeValidity = siteSettingsReducer.OTP_VALIDITY * 60;
  const SMSLimiter = now + resendLimit;
  const OTPexpiry = now + codeValidity;
  localStorage.setItem('VSMSBlockTime', JSON.stringify(SMSLimiter));
  localStorage.setItem('OTPexpiry', JSON.stringify(OTPexpiry));
};

export const sendSmsVerifyCode = async (data: any, guest = false) => {
  return await axios
    .post(guest ? POST_SEND_VERIFY_SMS_CODE_GUEST : POST_SEND_VERIFY_SMS_CODE, data)
    .then(() => {
      setOTPExpiry();
    });
};

export const sendEmailVerifyOTP = async (data: any) => {
  const siteSettingsReducer: any = store?.getState()?.siteSettingsReducer;
  const now = Math.floor(Date.now() / 1000);
  const resendLimit = siteSettingsReducer.SMS_TIMER * 60;
  const codeValidity = siteSettingsReducer.OTP_VALIDITY * 60;
  const SMSLimiter = now + resendLimit;
  const OTPexpiry = now + codeValidity;

  return await axios.post(POST_SEND_VERIFY_EMAIL_OTP, data).then(() => {
    localStorage.setItem('VSMSBlockTime', JSON.stringify(SMSLimiter));
    localStorage.setItem('OTPexpiry', JSON.stringify(OTPexpiry));
  });
};

export const updatePasswordGuest = async (customer_id: any, password: any, otp: any) => {
  return await axios.post(UPDATE_PASSWORD_GUEST, { customer_id, password, otp });
};

export const fetchOrder = async (id: any) => {
  return await axios.get(GET_ORDER_HISTORY + id);
};
