import * as actions from './actions';

const initialState: any = {
  loading: false,
  loadingFetchAddress: false,
  cart: null,
  address: null,
  selectedAddress: null,
  billingAddress: null,
  selectedBillingAddress: null,
  deliveryType: 'standard',
  paymentMethod: null,
  storeLocationId: null,
  company: null,
  guest: null,
  smsVerifyAsGuest: false,
  guestPhone: null,
  discountCode: null,
  referralCode: null,
  removeDiscountReferral: true,
  available_rewards: 0,
  formData: null,
  displayShippingPrice: false,
};

export default function checkoutV2Reducer(state: any = initialState, action: any) {
  switch (action.type) {
    case actions.SET_CHECKOUTV2_STATE:
      return {
        ...state,
        ...action?.payload,
      };

    default:
      return state;
  }
}
